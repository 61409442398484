import React, { PropsWithChildren, forwardRef } from 'react';
import { styled } from '@cp/ds/src/theme';
import { Box, BoxProps } from '@cp/ds/src/components/box';
import { name } from '../dialog/const';

const Layout = styled(Box, { name, slot: 'TextLayout' })({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  padding: '8px 6px 4px 12px ',
  maxWidth: '90%',
});

const IncomeLayout = styled(Layout)(({ theme }) => ({
  marginInlineEnd: 'auto',
  backgroundColor: theme.palette.white,
  border: `1px solid ${theme.palette['gray light']}`,
  borderRadius: '0px 12px 12px 12px',
}));

const OutgoingLayout = styled(Layout)(({ theme }) => ({
  marginInlineStart: 'auto',
  marginInlineEnd: 0,
  color: theme.palette.white,
  backgroundColor: theme.palette['gray'],
  borderRadius: '12px 0px 12px 12px',
}));

export interface TextMessageLayoutProps extends BoxProps, PropsWithChildren {
  outgoing?: boolean;
}
export const TextMessageLayout = forwardRef<HTMLDivElement, TextMessageLayoutProps>(({ outgoing, ...props }, ref) => {
  return outgoing ? <OutgoingLayout ref={ref} {...props} /> : <IncomeLayout ref={ref} {...props} />;
});
TextMessageLayout.displayName = 'TextMessageLayout';
