import React from 'react';
import capitalize from 'lodash/capitalize';
// eslint-disable-next-line import/no-duplicates
import { ru } from 'date-fns/locale';
import { Stack } from '@cp/ds/src/components/stack';
import { Typography } from '@cp/ds/src/components/typography';
// eslint-disable-next-line import/no-duplicates
import { format, nextDay } from 'date-fns';
import { DateFormat } from '@common/const/date';
import { IconButton } from '@cp/ds/src/components/iconButton';
import { CloseIcon, PlusIcon } from '@cp/ds/src/icons';
import { Button } from '@cp/ds/src/components/button';
import { MOBILE_BREAKPOINT_THRESHOLD } from '@cp/ds/src/theme';
import { useTheme } from '@cp/ds/src/hooks/useTheme';
import { Box } from '@cp/ds/src/components/box';
import { Item } from '../item';
import { AddItem } from '../addItem';
import { ScheduleSectionProps } from './model';

export const Section: React.FC<ScheduleSectionProps> = ({ dayOfWeek, intervals, onRemoveItem, onAddItem }) => {
  const theme = useTheme();
  const [isAddingItem, setAddingItem] = React.useState(false);

  const dayLabel = React.useMemo(() => format(nextDay(new Date(), dayOfWeek), DateFormat.DayOfWeekFull, { locale: ru }), [dayOfWeek]);

  return (
    <Stack
      direction="column"
      sx={{
        border: `1px solid ${theme.palette['gray light']}`,
        backgroundColor: 'gray ultra light',
        borderRadius: '12px',
        overflow: 'hidden',
        padding: 1.5,
      }}
    >
      <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography
          sx={{ alignSelf: { xs: intervals.length ? 'flex-start' : 'center', [MOBILE_BREAKPOINT_THRESHOLD]: 'center' } }}
          variant="body bold"
        >
          {capitalize(dayLabel)}
        </Typography>

        <Box
          sx={{
            [theme.breakpoints.up(MOBILE_BREAKPOINT_THRESHOLD)]: {
              display: 'flex',
              direction: 'row',
              gap: 1,
              alignItems: 'center',
            },
          }}
        >
          {intervals.length ? (
            <Stack
              direction={{ xs: 'column', [MOBILE_BREAKPOINT_THRESHOLD]: 'row' }}
              gap={{ xs: 1.5, [MOBILE_BREAKPOINT_THRESHOLD]: 1 }}
              sx={{
                flex: '1 1 0px',
                pl: 1,
                justifyContent: 'flex-end',
                flexWrap: 'wrap',
                mb: { xs: 1.5, [MOBILE_BREAKPOINT_THRESHOLD]: 0 },
              }}
            >
              {intervals.map((interval) => (
                <Item interval={interval} key={`${interval.from}-${interval.to}`} onRemove={onRemoveItem} />
              ))}
            </Stack>
          ) : null}

          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            {isAddingItem ? (
              <IconButton onClick={() => setAddingItem(false)}>
                <CloseIcon />
              </IconButton>
            ) : (
              <Button
                color="white"
                onClick={() => setAddingItem(true)}
                size="small"
                square
                sx={{ backgroundColor: 'gray light', borderRadius: '12px' }}
                variant="primary"
              >
                <PlusIcon />
              </Button>
            )}
          </Box>
        </Box>
      </Stack>

      {isAddingItem && (
        <AddItem
          dayOfWeek={dayOfWeek}
          onComplete={() => setAddingItem(false)}
          onRequest={onAddItem}
          sectionIntervals={intervals}
          sx={{ mt: 1.5, alignSelf: { xs: 'stretch', [MOBILE_BREAKPOINT_THRESHOLD]: 'flex-end' } }}
        />
      )}
    </Stack>
  );
};
