import React, { useMemo } from 'react';
//@ts-expect-error esm import
import { Box, Image } from '@cp/ui/components';
//@ts-expect-error esm import
import { cssToken } from '@cp/ui/utils';
import { BoxProps } from '@cp/ds/src/components/box';
import { styled } from '@cp/ds/src/theme';
import { getAssetSrc, getResizedAssetItems } from '@cp/shared/image';
import { ClientProfileViewModel } from '@common/model/client';
import { ClientPhotoPurpose } from '@common/model/client/photo/purpose';
import { ImageAsset } from '@common/model/resource/image';
import { getPhotoAsset, useFirstLetters } from '../model';

const name = 'ClientPhotoCard';
export const PhotoCardTokens = {
  colorText: cssToken(name, ['color', 'fg']),
  colorBackground: cssToken(name, ['color', 'bg']),
};

const Root = styled(Box, {
  name,
  shouldForwardProp: (propName) => propName !== 'size',
})(({ size }) => ({
  display: 'block',
  position: 'relative',
  borderRadius: '16px',
  overflow: 'hidden',
  width: `${size}px`,
  height: `${size}px`,
  '& img:not([src])': {
    visibility: 'hidden',
  },
}));
const Letters = styled(Box, { name, slot: 'Letters' })({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  userSelect: 'none',
  color: PhotoCardTokens.colorText('#fff'),
  backgroundColor: PhotoCardTokens.colorBackground('#FFCEC3'),
  fontWeight: 500,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
});

const getNearestSize = (asset: ImageAsset, wantedSize = 0): number => {
  const base = asset.base;
  const sizes = (base.images || []).map((image) => image.width);
  sizes.push(base.width);
  sizes.sort((a, b) => a - b);
  return sizes.find((size) => size >= wantedSize) || base.width;
};

export interface ClientPhotoCardProps extends BoxProps {
  size?: number;
  purpose?: ClientPhotoPurpose;
  client: Pick<ClientProfileViewModel, 'name' | 'patronymic' | 'surname' | 'mainPhoto' | 'photoSet'>;
}
export const ClientPhotoCard: React.FC<ClientPhotoCardProps> = ({ size, client, purpose, ...props }) => {
  const letters = useFirstLetters(client);
  const asset = useMemo(() => {
    if (client.photoSet) {
      return getPhotoAsset(client.photoSet, purpose);
    }
  }, [client.photoSet, purpose]);
  const assetItems = useMemo(() => (asset ? getResizedAssetItems(asset, getNearestSize(asset, size)) : []), [asset, size]);

  return (
    <Root size={size} {...props}>
      <Letters>{letters}</Letters>
      <picture>
        {assetItems.map((assetItem) => (
          <source key={assetItem.src} srcSet={assetItem.src} type={assetItem.mimeType} />
        ))}
        <Image
          alt={letters}
          height={size}
          loading="lazy"
          onError={(e) => (e.currentTarget.style.visibility = 'hidden')}
          src={asset ? getAssetSrc(asset) : undefined}
          sx={{ position: 'relative' }}
          width={size}
        />
      </picture>
    </Root>
  );
};
ClientPhotoCard.displayName = name;
