import { CATALOG_ITEM_PARAMETER_TO_ALLOWED_CAREER_IDS } from '@common/model/catalog/item/parameterToAllowedCareerIds';
import { CATALOG_ITEM_ADDITIONAL_PARAMETERS_SEQUENCE } from '@common/model/catalog/item/additionalParameters';
import { CatalogItemType } from '@common/model/catalog/item/type';
import { ClientResumeUpdateRequest } from '@common/model/client/resume/updateRequest';
import { CatalogItemViewModel } from '@common/model/catalog/item/view';
import { LanguageCode } from '@common/model/resource/language';

export class AdditionalEditFormModel extends ClientResumeUpdateRequest {}

export interface IUseFormDataReturn {
  defaultValues: AdditionalEditFormModel;
  fieldNames: (keyof AdditionalEditFormModel)[];
}

export const useAdditionalEditFormData = (resume: CatalogItemViewModel, languageCode: LanguageCode): IUseFormDataReturn => {
  if (resume.type !== CatalogItemType.Resume) {
    throw new Error('Invalid type');
  }

  const result: IUseFormDataReturn = {
    defaultValues: {
      careerId: resume.careerId,
      translations: [
        {
          languageCode,
          attitudeTowardsPets: resume.translation?.attitudeTowardsPets,
        },
      ],
    },
    fieldNames: [],
  };

  for (const key of CATALOG_ITEM_ADDITIONAL_PARAMETERS_SEQUENCE) {
    const allowedCareerIds = CATALOG_ITEM_PARAMETER_TO_ALLOWED_CAREER_IDS[key];

    if (!allowedCareerIds?.includes(resume.careerId)) {
      continue;
    }

    result.fieldNames.push(key as keyof AdditionalEditFormModel);
    result.defaultValues[key] = resume[key];
  }

  return result;
};
