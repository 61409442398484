import { EntityState, PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { AdminCatalogItemViewModel } from '@common/model/catalog/item/admin.view';

type Entity = AdminCatalogItemViewModel;
const cardsAdapter = createEntityAdapter<Entity>();

export type MonitorState = {
  columns: Record<string, number[]>;
  cards: EntityState<Entity>;
};

const { selectById } = cardsAdapter.getSelectors();
const name = 'vacancyMonitor';

export type ReducerStateShape = { [name]: MonitorState };

const initialState: MonitorState = {
  columns: {},
  cards: cardsAdapter.getInitialState(),
};

export const slice = createSlice({
  name,
  initialState,
  reducers: {
    fill: (state, action: PayloadAction<{ key: string; items: Entity[] }>) => {
      const { key, items } = action.payload;
      cardsAdapter.addMany(state.cards, items);
      const itemsIds: number[] = state.columns[key] || [];
      for (const item of action.payload.items) {
        if (itemsIds.includes(item.id)) {
          continue;
        }
        itemsIds.push(item.id);
      }
      state.columns[key] = itemsIds;
    },
    updateOne: (state, action: Parameters<typeof cardsAdapter.updateOne>[1]) => {
      cardsAdapter.updateOne(state.cards, action);
    },
    clear: () => {
      return initialState;
    },
  },
});

export const selectColumnItems = (state: ReducerStateShape, key: string): Entity[] => {
  const sliceState = state[name];
  const ids = sliceState.columns[key] || [];
  const items: Entity[] = [];
  let item: Entity | undefined;
  for (const id of ids) {
    item = selectById(sliceState.cards, id);
    if (!item) {
      continue;
    }
    items.push(item);
  }
  return items;
};

export const reducerConfig = {
  [name]: slice.reducer,
};
