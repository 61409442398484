import React, { useRef } from 'react';
import { MessageType } from '@common/model/dialog/message/messageType';
import { DialogMessageRenderer } from '../../lib';
import { useIsOutgoing } from '../../model';
import { useReadNotification } from '../../model/useReadNotification';
import { MessageDelivery } from '../messageDelivery';
import { MessageDate } from '../mesageDate';
import { DialogMessageText } from './messageText';
import { TextMessageLayout } from './textLayout';
import { MessageFooter } from './footer';

export const DialogTextMessage: DialogMessageRenderer<MessageType.Text> = ({ message, isPreview }) => {
  const { payload } = message;
  const outgoing = useIsOutgoing(message);
  const ref = useRef<HTMLDivElement>(null);
  useReadNotification(ref, message);

  if (isPreview) {
    return <DialogMessageText payload={payload} textOnly={isPreview} />;
  }

  return (
    <TextMessageLayout id={message.draftId} outgoing={outgoing} ref={ref}>
      <DialogMessageText payload={payload} />
      <MessageFooter>
        <MessageDelivery message={message} />
        <MessageDate message={message} />
      </MessageFooter>
    </TextMessageLayout>
  );
};
