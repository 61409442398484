import { SelectItemModel } from '@cp/ds/src/components/select/model';
import { msToTimeString, timeStringToMs } from '@cp/shared/lib';
import { CatalogItemScheduleIntervalViewModel } from '@common/model/catalog/item/schedule/interval.view';
import { CATALOG_ITEM_SCHEDULE_INTERVAL_TIME_STEP_IN_MS } from '@common/const/catalog/item';
import { MS_IN_DAY } from '@common/const/time';

export interface ItemCreationData {
  startFieldItems: SelectItemModel[];
  endFieldItems: SelectItemModel[];
}

export const TIME_STEP_IN_MS = CATALOG_ITEM_SCHEDULE_INTERVAL_TIME_STEP_IN_MS;

export const generateItemCreationData = ({
  intervals,
  startFieldValue,
  endFieldValue,
}: {
  intervals: CatalogItemScheduleIntervalViewModel[];
  startFieldValue: string | null;
  endFieldValue: string | null;
}): ItemCreationData => {
  const startFieldValueInMs = startFieldValue ? timeStringToMs(startFieldValue) : undefined;
  const endFieldValueInMs = endFieldValue ? timeStringToMs(endFieldValue) : undefined;

  /**
   * 1. Validate "start" and "end" field values by checking
   * that they are not contained by any of existed intervals
   */
  for (const interval of intervals) {
    if (
      startFieldValueInMs !== undefined &&
      interval.fromInMsFromDayStart < startFieldValueInMs &&
      startFieldValueInMs < interval.toInMsFromDayStart
    ) {
      throw new Error('Invalid "from" value');
    }

    if (
      endFieldValueInMs !== undefined &&
      interval.fromInMsFromDayStart < endFieldValueInMs &&
      endFieldValueInMs < interval.toInMsFromDayStart
    ) {
      throw new Error('Invalid "to" value');
    }
  }

  /**
   * 2. Calc available range
   */
  let availableRangeFromInMs = 0;
  let availableRangeToInMs = MS_IN_DAY;

  /**
   * Calc range by searching for first and last time marks,
   * which are not contained by some existed interval
   */
  const sortedIntervals = intervals.slice().sort((a, b) => a.fromInMsFromDayStart - b.fromInMsFromDayStart);

  for (const interval of sortedIntervals) {
    if (availableRangeFromInMs < interval.fromInMsFromDayStart) {
      break;
    }

    availableRangeFromInMs = interval.toInMsFromDayStart;
  }

  const reversedSortedIntervals = sortedIntervals.reverse();

  for (const interval of reversedSortedIntervals) {
    if (availableRangeToInMs > interval.toInMsFromDayStart) {
      break;
    }

    availableRangeToInMs = interval.fromInMsFromDayStart;
  }

  /**
   * 3. Generate items
   * 3.1. Generate items for "start" field inside available range and then filter them
   * by checking that they are not contained by some existing interval
   */
  const result: ItemCreationData = {
    startFieldItems: [],
    endFieldItems: [],
  };

  const startFieldItemsValuesInMs: number[] = [];

  let startFieldItemsValueInMs = availableRangeFromInMs;
  const startFieldItemsMaxValueInMs = availableRangeToInMs - TIME_STEP_IN_MS;

  while (startFieldItemsValueInMs <= startFieldItemsMaxValueInMs) {
    startFieldItemsValuesInMs.push(startFieldItemsValueInMs);
    startFieldItemsValueInMs += TIME_STEP_IN_MS;
  }

  const filteredStartFieldItemsValuesInMs = startFieldItemsValuesInMs.filter((valueInMs) => {
    return !intervals.some(
      ({ fromInMsFromDayStart, toInMsFromDayStart }) =>
        fromInMsFromDayStart - TIME_STEP_IN_MS < valueInMs && valueInMs < toInMsFromDayStart,
    );
  });

  for (const valueInMs of filteredStartFieldItemsValuesInMs) {
    const timeString = msToTimeString(valueInMs);

    result.startFieldItems.push({
      value: timeString,
      label: timeString,
    });
  }

  /**
   * 3.2. Generate items for "end" field only if "start" field is filled
   */
  if (startFieldValueInMs !== undefined) {
    /**
     * 3.2.1. Calc range between bordering intervals if they exists
     */
    const availableRangeForEndFieldFromInMs = startFieldValueInMs + TIME_STEP_IN_MS;
    let availableRangeForEndFieldToInMs = availableRangeToInMs;

    for (const interval of reversedSortedIntervals) {
      if (interval.fromInMsFromDayStart < availableRangeForEndFieldFromInMs) {
        break;
      }

      availableRangeForEndFieldToInMs = Math.min(availableRangeForEndFieldToInMs, interval.fromInMsFromDayStart);
    }

    /**
     * 3.2.2. Generate items
     */
    let endFieldItemsValueInMs = availableRangeForEndFieldFromInMs;
    const endFieldItemsMaxValueInMs = availableRangeForEndFieldToInMs;

    while (endFieldItemsValueInMs <= endFieldItemsMaxValueInMs) {
      if (endFieldItemsValueInMs === MS_IN_DAY) {
        endFieldItemsValueInMs -= 1;
      }
      const timeString = msToTimeString(endFieldItemsValueInMs);

      result.endFieldItems.push({
        value: timeString,
        label: timeString,
      });

      endFieldItemsValueInMs += TIME_STEP_IN_MS;
    }
  }

  return result;
};
