export enum MediaType {
  unknown = 'image/unknown',
  webp = 'image/webp',
  png = 'image/png',
  jpg = 'image/jpeg',
  svg = 'image/svg+xml',
}

const getMediaTypeFromDataString = (dataString: string): MediaType => {
  const meta = dataString.substring(5, dataString.indexOf(','));
  const mime = meta.substring(0, meta.indexOf(';'));
  for (const type in MediaType) {
    if (MediaType[type] === mime) {
      return MediaType[type];
    }
  }
  return MediaType.unknown;
};

export const assumeMediaTypeByFileUrl = (url: string): MediaType => {
  if (url.startsWith('data:')) {
    return getMediaTypeFromDataString(url);
  }
  switch (
    url
      .substring(url.lastIndexOf('/') + 1)
      .split('.')
      .pop()
  ) {
    case 'webp':
      return MediaType.webp;
    case 'png':
      return MediaType.png;
    case 'jpg':
    case 'jpeg':
      return MediaType.jpg;
    case 'svg':
      return MediaType.svg;
    default:
      return MediaType.unknown;
  }
};
