import React, { useRef } from 'react';
import { ApiServerEntityIDType } from '@cp/utils/apiServer/types';
import { Stack } from '@cp/ds/src/components/stack';
import { ClientPhotoCard } from '@cp/entities/clientPhoto';
import { useTheme } from '@cp/ds/src/hooks/useTheme';
import { AuthorRegistrationDate } from '@cp/entities/resume/ui';
import { Skeleton } from '@cp/ds/src/components/skeleton';
import { ClientPhotoPurpose } from '@common/model/client/photo/purpose';
import { CareerTag } from '@cp/entities/career';
import { Box } from '@cp/ds/src/components/box';
import { CAREER_IDS_WITH_ENABLED_ADDITIONAL } from '@common/const/catalog/item';
import { SubjectModalView } from 'src/shared/ui/subjectModalView';
import { ModerationUpdateForResumeButton } from 'src/features/moderation/updateForResume';
import { useClientPhotoEditingCreator } from 'src/features/client/photo/edit';
import { useResumeData } from 'src/entities/resume';
import { ResumeAboutMeEditableSection } from 'src/features/resume/edit/ui/aboutMeSection';
import { ClientPersonalInformationEditableSection } from 'src/features/client/personalInformationEdit';
import { ResumeScheduleEditableSection } from 'src/features/resume/edit/ui/scheduleSection';
import { ResumeOptionsEditableSection, ResumeTariffsEditableSection } from 'src/features/resume/edit';
import { ResumeAdditionalEditableSection } from 'src/features/resume/edit/ui/additionalSection';
import { ClientContactsEditableSection } from 'src/features/client/contactsEdit';
import { DialogViewButton } from 'src/features/dialog/view';
import { ClientDeleteButton } from 'src/features/client/delete';
import { ClientFeedbacksEditableSection } from 'src/features/client/feedbacks';
import { ToggleClientDocumentsCheck } from 'src/features/moderation/toggleClientDocumentsCheck';
import { NotificationSubscriptions } from 'src/entities/client';
import { IResumeViewHandle, ResumeViewProps } from './model';

export * from './model';

export const ResumeView = React.forwardRef<IResumeViewHandle, ResumeViewProps>((props, ref) => {
  const theme = useTheme();
  const [resumeId, setResumeId] = React.useState<ApiServerEntityIDType | null>(null);
  const { resume, isLoadingResume } = useResumeData({ resumeId });
  const et = useRef(new EventTarget());
  const [createPhotoEditHandler] = useClientPhotoEditingCreator();

  const hide = React.useCallback(() => {
    setResumeId(null);
    et.current.dispatchEvent(new CustomEvent('close'));
  }, []);

  React.useImperativeHandle(
    ref,
    () => ({
      view: (id: ApiServerEntityIDType, onClose) => {
        setResumeId(id);
        if (onClose) {
          et.current.addEventListener('close', () => onClose(), { once: true });
        }
      },
      hide,
    }),
    [hide],
  );

  const dataForClientPhotoResume = React.useMemo(() => {
    return {
      ...resume?.clientProfile,
      mainPhoto: resume?.clientProfile.photoSet?.find((p) => p.purpose === ClientPhotoPurpose.Resume),
    };
  }, [resume?.clientProfile]);

  return (
    <SubjectModalView
      buttons={
        <Stack alignItems="center" direction="row" gap={2}>
          <ModerationUpdateForResumeButton resumeId={resumeId} />
          <DialogViewButton
            color="white"
            dialogFindQuery={resume?.clientProfile ? { clientId: resume.clientProfile.id } : undefined}
            sx={{ border: `1px solid ${theme.palette['gray light']}` }}
            variant="primary"
          />
          <ClientDeleteButton clientId={resume?.clientProfile.id} color="white" />
        </Stack>
      }
      isOpen={Boolean(resumeId)}
      onClose={hide}
    >
      <Stack direction="column" gap={2.5} sx={{ pb: 5 }}>
        <Stack direction="row" gap={3} sx={{ alignItems: 'flex-start' }}>
          <ClientPhotoCard
            data={dataForClientPhotoResume}
            onClick={createPhotoEditHandler({ clientProfile: resume?.clientProfile, purpose: ClientPhotoPurpose.Resume })}
            size={334}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2.5 }}>
            <ClientPhotoCard
              data={resume?.clientProfile}
              onClick={createPhotoEditHandler({ clientProfile: resume?.clientProfile, purpose: ClientPhotoPurpose.Avatar })}
              size={167}
            />
            <Box sx={{ display: 'flex', gap: 1 }}>
              {resume?.clientProfile?.notificationPlatforms?.length ? (
                <NotificationSubscriptions data={resume?.clientProfile?.notificationPlatforms} />
              ) : null}
              {resume ? <CareerTag careerId={resume.careerId} /> : null}
            </Box>
          </Box>
        </Stack>

        <ClientPersonalInformationEditableSection clientProfile={resume?.clientProfile} isLoading={isLoadingResume} />

        <ToggleClientDocumentsCheck clientProfile={resume?.clientProfile} />

        <ResumeAboutMeEditableSection resumeId={resumeId} />

        <ResumeOptionsEditableSection resumeId={resumeId} />

        <ResumeScheduleEditableSection resumeId={resumeId} />

        <ResumeTariffsEditableSection resumeId={resumeId} />

        {CAREER_IDS_WITH_ENABLED_ADDITIONAL.includes(resume?.careerId || 0) && <ResumeAdditionalEditableSection resumeId={resumeId} />}

        <ClientContactsEditableSection clientId={resume?.clientProfile.id} />

        <ClientFeedbacksEditableSection clientId={resume?.clientProfile.id} mainResumeId={resumeId} />

        {resume ? <AuthorRegistrationDate data={resume} /> : <Skeleton width={200} />}
      </Stack>
    </SubjectModalView>
  );
});
