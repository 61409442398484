import React, { PropsWithChildren } from 'react';
import { styled } from '@cp/ds/src/theme';
import { Box } from '@cp/ds/src/components/box';
import { Tokens, name } from './const';
import { DialogContainer } from './container';

const Pinned = styled(Box, { name, slot: 'Pinned' })({
  display: 'flex',
  flexDirection: 'column',
  gap: Tokens.listGap(),
  marginBlockStart: Tokens.listGap(),
  marginBlockEnd: Tokens.listGap(),
  '&:empty': { display: 'none' },
});

export const PinnedWrapper = (props: PropsWithChildren) => (
  <DialogContainer>
    <Pinned>{props.children}</Pinned>
  </DialogContainer>
);
