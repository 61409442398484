import { styled } from '@cp/ds/src/theme';
import { Box } from '@cp/ds/src/components/box';
import { DESKTOP_LAYOUT_WIDTH, Tokens, name } from './const';

export const HeaderWrapper = styled(Box, { name, slot: 'HeaderWrapper' })(({ theme }) => ({
  zIndex: 5,
  boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.13)',
  position: 'relative',
  backgroundColor: Tokens.colorPaper(),
  flexShrink: 0,
  padding: `${theme.spacing(1.25)} ${theme.spacing(1.5)}`,
  [`@container (min-width: ${DESKTOP_LAYOUT_WIDTH})`]: {
    padding: `${theme.spacing(3)} ${theme.spacing(5)} ${theme.spacing(2)}`,
  },
}));
