// @ts-expect-error esm import
import { cssToken } from '@cp/ui/utils';

export const name = 'Dialog';

/**
 * Parent container width to render desktop layout
 */
export const DESKTOP_LAYOUT_WIDTH = '600px';
export const Tokens = {
  gap: cssToken(name, ['gap']),
  maxWidth: cssToken(name, 'maxWidth'),
  /**
   * Gap between elements of message list
   */
  listGap: cssToken(name, ['dimension', 'list', 'gap']),
  headerGap: cssToken(name, ['dimension', 'header', 'gap']),
  listBackground: cssToken(name, ['color', 'list', 'background']),
  colorPaper: cssToken(name, ['color', 'paper']),
  blockHeaderLine: cssToken(name, ['color', 'header', 'line']),
  blockHeaderText: cssToken(name, ['color', 'header', 'text']),
};
