import { styled } from '@cp/ds/src/theme';
import { DialogContainer } from './container';
import { Tokens, name } from './const';

export const ListWrapper = styled(DialogContainer, { name, slot: 'ListWrapper' })({
  flexGrow: 1,
  flexShrink: 1,
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column-reverse',
  paddingBottom: Tokens.listGap(Tokens.gap()),
  backgroundColor: Tokens.listBackground(),
});
