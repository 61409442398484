import React from 'react';
import { Stack } from '@cp/ds/src/components/stack';
import { Button } from '@cp/ds/src/components/button';
import { FormField } from '@cp/ds/src/components/form';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { AppFormProvider } from '@cp/utils/form';
import { MOBILE_BREAKPOINT_THRESHOLD, SxProps } from '@cp/ds/src/theme';
import { mergeSx } from '@cp/ds/src/utils/sx/merge';
import { useTheme } from '@cp/ds/src/hooks/useTheme';
import { handleApiServerError } from '@cp/utils/apiServer/errorHandler';
import { ItemCreateFormModel, useItemCreationData } from '../../../../../../client.web/src/entities/resume/model';
import { AddItemProps } from './model';

export const AddItem: React.FC<AddItemProps> = ({ dayOfWeek, sectionIntervals, onRequest, onComplete, sx }) => {
  const theme = useTheme();

  const fieldSx = React.useMemo<SxProps>(
    () => ({
      [theme.breakpoints.down(MOBILE_BREAKPOINT_THRESHOLD)]: {
        flex: '1 1 0px',
      },
      [theme.breakpoints.up(MOBILE_BREAKPOINT_THRESHOLD)]: {
        width: 120,
      },
    }),
    [theme.breakpoints],
  );

  const form = useForm<ItemCreateFormModel>({
    defaultValues: {
      dayOfWeek,
    },
  });

  // Reason to use 'useWatch' and 'getValues' together:
  // https://github.com/react-hook-form/react-hook-form/issues/2738
  useWatch<ItemCreateFormModel, 'from'>({ name: 'from', control: form.control });
  const fromValue = form.getValues('from');

  // Reason to use 'useWatch' and 'getValues' together:
  // https://github.com/react-hook-form/react-hook-form/issues/2738
  useWatch<ItemCreateFormModel, 'to'>({ name: 'to', control: form.control });
  const toValue = form.getValues('to');

  const { startFieldItems: fromItems, endFieldItems: toItems } = useItemCreationData({
    intervals: sectionIntervals,
    startFieldValue: fromValue,
    endFieldValue: toValue,
  });

  const handleSubmit = React.useCallback<SubmitHandler<ItemCreateFormModel>>(
    async (values) => {
      const response = await onRequest(values);

      if ('error' in response) {
        handleApiServerError(response.error, { form });
      } else {
        onComplete();
      }
    },
    [form, onComplete, onRequest],
  );

  return (
    <AppFormProvider form={form}>
      <Stack
        component="form"
        direction={{ xs: 'column', [MOBILE_BREAKPOINT_THRESHOLD]: 'row' }}
        gap={1}
        noValidate
        onSubmit={form.handleSubmit(handleSubmit)}
        sx={mergeSx(sx, { alignItems: { xs: 'stretch', [MOBILE_BREAKPOINT_THRESHOLD]: 'center' } })}
      >
        <Stack direction="row" gap={1}>
          <FormField
            fieldContainerProps={{ sx: fieldSx }}
            itemListHeightMax={200}
            items={fromItems}
            name="from"
            placeholder="Начало"
            size="small"
            sx={{ width: 1 }}
            type="select"
          />
          <FormField
            disabled={!fromValue}
            fieldContainerProps={{ sx: fieldSx }}
            itemListHeightMax={200}
            items={toItems}
            name="to"
            placeholder="Конец"
            size="small"
            sx={{ width: 1 }}
            type="select"
          />
        </Stack>
        <Button color="pink" disabled={!fromValue || !toValue} size="small" type="submit" variant="primary">
          Добавить
        </Button>
      </Stack>
    </AppFormProvider>
  );
};
