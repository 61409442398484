import React from 'react';
import { StyledTooltip } from './components/styledTooltip';
import { TooltipProps } from './model';
export type { TooltipProps } from './model';

export const Tooltip: React.FC<TooltipProps> = (props) => {
  const { children, colorSchema = 'dark', bordered = false, ...otherProps } = props;
  const ownerState = {
    colorSchema,
    bordered,
  };
  return (
    <StyledTooltip ownerState={ownerState} {...otherProps}>
      {children}
    </StyledTooltip>
  );
};
