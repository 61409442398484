import React from 'react';
import {
  ResumeAdditionalEdit as ResumeAdditionalEditEntity,
  ResumeAdditionalEditProps as ResumeAdditionalEditEntityProps,
} from '@cp/entities/resume';
import { createFromValidationResolver } from '@cp/utils/form';
import { Divider } from '@cp/ds/src/components/divider';
import { AdditionalEditFormModel } from '@cp/entities/resume/model';
import { MODERATOR_MESSAGE_FIELD_NAME_DEFAULT } from 'src/entities/moderation/ui/moderatorMessageField/const';
import { ModerationMessageFormModel, ModeratorMessageField } from 'src/entities/moderation';
import { adminResumeApi } from 'src/entities/resume/api';
import { useApiServerErrorHandler } from 'src/app/shared/hooks/useApiServerErrorHandler';
import { useSendMessageToClient } from 'src/features/dialog/sendMessageToClient';
import { ResumeAdditionalEditProps } from './model';

const moderationMessageFormResolver = createFromValidationResolver(ModerationMessageFormModel);
const resolvers = [moderationMessageFormResolver];

export const ResumeAdditionalEdit: React.FC<ResumeAdditionalEditProps> = ({ data, onComplete }) => {
  const handleApiServerError = useApiServerErrorHandler();
  const sendMessageToClient = useSendMessageToClient();
  const [update] = adminResumeApi.endpoints.updateResumeById.useMutation();

  const handleSubmit: ResumeAdditionalEditEntityProps['onSubmit'] = async (values, { form }) => {
    if (!data.clientProfile) {
      throw new Error('ClientProfile is required');
    }

    const { [MODERATOR_MESSAGE_FIELD_NAME_DEFAULT]: message, ...restValues } = values as AdditionalEditFormModel &
      ModerationMessageFormModel;

    const response = await update({ body: restValues, params: { id: data.id } });

    if ('error' in response) {
      handleApiServerError(response.error, { form });
    } else {
      if (message) {
        await sendMessageToClient({ clientId: data.clientProfile.id, message: message ?? '' }, { form });
      }
      onComplete?.();
    }
  };

  return (
    <ResumeAdditionalEditEntity
      afterFields={
        <>
          <Divider sx={{ my: 2.5 }} />
          <ModeratorMessageField />
        </>
      }
      data={data}
      onSubmit={handleSubmit}
      resolvers={resolvers}
    />
  );
};
