import React from 'react';
import { Box } from '@cp/ds/src/components/box';
import { ClientType } from '@common/model/client/clientType';
import { Specialists } from './specialists';
import { Employers } from './employers';
import { Vacancies } from './vacancies';
import { ActiveSubscriptions } from './activeSubscriptions';
import { ClientActivity } from './clientActivity';
import { SmsBalance } from './smsBalance';

export const CommonStats = () => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', flexWrap: 'nowrap', overflowY: 'auto', gap: 2 }}>
      <SmsBalance />
      <Specialists />
      <Employers />
      <Vacancies />
      <ActiveSubscriptions />
      <ClientActivity
        clientType={ClientType.Specialist}
        title="Сумарное кол-во специалистов проявивших активность за последние  7 дн. (ботом созданные не учитываются). В скобках = новореги"
      />
      <ClientActivity
        clientType={ClientType.Employer}
        title="Сумарное кол-во нанимателей проявивших активность за последние  7 дн. (ботом созданные не учитываются). В скобках = новореги"
      />
    </Box>
  );
};
