import { CareerId } from '../../../const/catalog/career';
import {
  CAREER_IDS_WITH_APARTMENT_PARAMETER,
  CAREER_IDS_WITH_BABY_PARAMETER,
  CAREER_IDS_WITH_BEDRIDDEN_PATIENT_PARAMETER,
  CAREER_IDS_WITH_CHILDREN_PARAMETER,
  CAREER_IDS_WITH_CHILD_WITH_DISABILITIES_PARAMETER,
  CAREER_IDS_WITH_COUNTRY_HOUSE_PARAMETER,
  CAREER_IDS_WITH_FREE_MEETING_WITH_PARENTS_PARAMETER,
  CAREER_IDS_WITH_LIVING_AT_CONSUMERS_HOME,
  CAREER_IDS_WITH_PATRONAGE_PARAMETER,
  CAREER_IDS_WITH_PRESCHOOLER_PARAMETER,
  CAREER_IDS_WITH_REGULAR_SPECIALIST_PARAMETER,
  CAREER_IDS_WITH_SCHOOLCHILD_PARAMETER,
  CAREER_IDS_WITH_WORKING_AT_CONSUMERS_HOME_PARAMETER,
  CAREER_IDS_WITH_WORKING_AT_HOSPITAL_PARAMETER,
} from '../../../const/catalog/item';
import { CatalogItemViewModel } from './view';

export const CATALOG_ITEM_PARAMETER_TO_ALLOWED_CAREER_IDS: Partial<Record<keyof CatalogItemViewModel, CareerId[]>> = {
  baby: CAREER_IDS_WITH_BABY_PARAMETER,
  childWithDisabilities: CAREER_IDS_WITH_CHILD_WITH_DISABILITIES_PARAMETER,
  regularSpecialist: CAREER_IDS_WITH_REGULAR_SPECIALIST_PARAMETER,
  children: CAREER_IDS_WITH_CHILDREN_PARAMETER,
  freeMettingWithParents: CAREER_IDS_WITH_FREE_MEETING_WITH_PARENTS_PARAMETER,
  bedriddenPatient: CAREER_IDS_WITH_BEDRIDDEN_PATIENT_PARAMETER,
  workingAtHospital: CAREER_IDS_WITH_WORKING_AT_HOSPITAL_PARAMETER,
  patronage: CAREER_IDS_WITH_PATRONAGE_PARAMETER,
  workingAtConsumersHome: CAREER_IDS_WITH_WORKING_AT_CONSUMERS_HOME_PARAMETER,
  preschooler: CAREER_IDS_WITH_PRESCHOOLER_PARAMETER,
  schoolchild: CAREER_IDS_WITH_SCHOOLCHILD_PARAMETER,
  apartment: CAREER_IDS_WITH_APARTMENT_PARAMETER,
  countryHouse: CAREER_IDS_WITH_COUNTRY_HOUSE_PARAMETER,
  livingAtConsumersHome: CAREER_IDS_WITH_LIVING_AT_CONSUMERS_HOME,
};
