import { AdminCatalogItemViewModel } from '@common/model/catalog/item/admin.view';
import { NotificationSendingTransportCondition, NotificationTransport } from '@common/model/notification/transport';
import { NotificationSendingTransportConfigRequest } from '@common/model/notification/transport.config.request';
import { Button, ButtonProps } from '@cp/ds/src/components/button';
import { CheckmarkIcon } from '@cp/ds/src/icons';
import { useTitle } from '@cp/entities/vacancy';
import React from 'react';
import { VACANCY_ANNOUNCE_MODAL_NAME } from 'src/app/shared/components/modals/vacancyAnnounce/const';
import { useModal } from 'src/app/shared/hooks/useModal';
import { adminCatalogItemApi } from 'src/entities/catalog/item';

interface VacancyAnnounceButtonProps extends ButtonProps {
  vacancy: AdminCatalogItemViewModel;
  onUpdate?: (update: Partial<AdminCatalogItemViewModel>) => void;
}

export const VacancyAnnounceButton: React.FC<VacancyAnnounceButtonProps> = ({ vacancy, onUpdate, ...restProps }) => {
  const { openModal } = useModal();
  const title = useTitle(vacancy);
  const [announce, { isLoading }] = adminCatalogItemApi.endpoints.announceCatalogItem.useMutation();

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();

    openModal(VACANCY_ANNOUNCE_MODAL_NAME, {
      defaultValues: {
        message: `🔥 Вакансия: ${title}`,
      },
      onSubmit: async (values, { onClose }) => {
        const transports: NotificationSendingTransportConfigRequest[] = [
          { transport: NotificationTransport.Push, condition: NotificationSendingTransportCondition.Always },
        ];

        if (values.sms) {
          transports.push({
            transport: NotificationTransport.Sms,
            condition: NotificationSendingTransportCondition.IfNotSentYetByOtherTransports,
          });
        }

        const prevIsAnnounced = vacancy.isAnnounced;
        onUpdate?.({ isAnnounced: true });

        try {
          await announce({ id: vacancy.id, transports, message: values.message }).unwrap();
          onClose();
        } catch {
          onUpdate?.({ isAnnounced: prevIsAnnounced });
        }
      },
    });
  };

  return (
    <Button
      color="pink"
      disabled={isLoading}
      iconRight={vacancy.isAnnounced ? <CheckmarkIcon /> : undefined}
      onClick={handleClick}
      size="extraSmall"
      variant="primary"
      {...restProps}
    >
      Push
    </Button>
  );
};
