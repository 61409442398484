import React from 'react';
import { Stack } from '@cp/ds/src/components/stack';
import { ContentSkeleton } from '@cp/ds/src/components/contentSkeleton';
import { useResumeScheduleData } from '@cp/entities/resume';
import { ResumeScheduleEditProps } from './model';
import { Section } from './section';

export * from './model';

export const ResumeScheduleEdit: React.FC<ResumeScheduleEditProps> = ({ data, isLoading, onRemoveItem, onAddItem, sx }) => {
  const { sections } = useResumeScheduleData({ data });

  if (isLoading) {
    return <ContentSkeleton blocks={1} />;
  }

  return (
    <Stack direction="column" gap={1.5} sx={sx}>
      {sections.map((sectionProps) => (
        <Section key={sectionProps.dayOfWeek} onAddItem={onAddItem} onRemoveItem={onRemoveItem} {...sectionProps} />
      ))}
    </Stack>
  );
};
