import { MessageType } from '@common/model/dialog/message/messageType';
import React from 'react';
import { DialogMessageRenderer, DialogTextMessage } from '@cp/entities/dialog';

export const VacancyResponseRenderer: DialogMessageRenderer<MessageType.VacancyResponse> = (props) => {
  return (
    <DialogTextMessage
      {...props}
      message={{ ...props.message, type: MessageType.Text, payload: 'Здравствуйте, готова выполнить ваш заказ' }}
    />
  );
};
