import { coreApi } from '@cp/shared/api/core';
import { ApiRoutePathParams } from '@common/api/routePathParams';
import { ApiRoute } from '@common/api/route';
import { generateApiUrl } from '@common/api/configure';
import { AdminCatalogItemsListResponse } from '@common/model/catalog/item/adminList.request';
import { ApiRouteQueryParams } from '@common/api/routeQueryParams';
import { AdminCatalogItemViewModel } from '@common/model/catalog/item/admin.view';
import { ApiServerEntityIDType } from '@common/types';
import { apiRouteMethodDict } from '@common/api/routeMethod';
import { AdminCatalogItemAnnounceRequest } from '@common/model/admin/catalog/item/announce.request';
import { AdminCatalogItemApiTag, adminCatalogItemApiTags } from './tags';

export const adminCatalogItemApi = coreApi
  .enhanceEndpoints({
    addTagTypes: adminCatalogItemApiTags,
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getCatalogItem: builder.query<AdminCatalogItemViewModel, { params: ApiRoutePathParams[ApiRoute.AdminCatalogItemGetOne] }>({
        query: ({ params }) => {
          return {
            url: generateApiUrl(ApiRoute.AdminCatalogItemGetOne, params),
          };
        },
        providesTags: (_result, _error, { params: { id } }) => [{ type: AdminCatalogItemApiTag.Item, id }],
      }),
      getList: builder.query<AdminCatalogItemsListResponse, ApiRouteQueryParams[ApiRoute.AdminCatalogItemsGet]>({
        query: (params) => ({
          url: generateApiUrl(ApiRoute.AdminCatalogItemsGet),
          params,
        }),
        providesTags: [{ type: AdminCatalogItemApiTag.ItemList }],
        keepUnusedDataFor: 10,
      }),
      deleteCatalogItem: builder.mutation<void, { id: ApiServerEntityIDType }>({
        query: (params) => ({
          url: generateApiUrl(ApiRoute.AdminCatalogItemDelete, { id: params.id }),
          method: apiRouteMethodDict[ApiRoute.AdminCatalogItemDelete],
        }),
        invalidatesTags: (_result, _error, { id }) => [
          { type: AdminCatalogItemApiTag.Item, id },
          { type: AdminCatalogItemApiTag.ItemList },
        ],
      }),
      announceCatalogItem: builder.mutation<void, { id: ApiServerEntityIDType } & AdminCatalogItemAnnounceRequest>({
        query: ({ id, ...restParams }) => ({
          url: generateApiUrl(ApiRoute.AdminCatalogItemAnnounce, { id }),
          method: apiRouteMethodDict[ApiRoute.AdminCatalogItemAnnounce],
          body: restParams,
        }),
      }),
    }),
  });
