import React from 'react';
import { useCurrentLanguage } from '@cp/shared/localization';
import { FormContext } from '@cp/utils/form/formContext';
import { useIsMobile } from '@cp/ds/src/hooks/useIsMobile';
import { FormProvider, useForm } from 'react-hook-form';
import { Button } from '@cp/ds/src/components/button';
import { FormField } from '@cp/ds/src/components/form';
import { MOBILE_BREAKPOINT_THRESHOLD } from '@cp/ds/src/theme';
import { Box } from '@cp/ds/src/components/box';
import { composeResolvers, createFromValidationResolver } from '@cp/utils/form/resolver';
import { CATALOG_ITEM_ADDITIONAL_PARAMETERS_DICT } from '@common/model/catalog/item/additionalParameters';
import { CAREER_IDS_WITH_ATTITUDE_TOWARDS_PETS_PARAMETER } from '@common/const/catalog/item';
import { Stack } from '@cp/ds/src/components/stack';
import { AdditionalEditFormModel, useAdditionalEditFormData } from '../../model';
import { AdditionalEditProps } from './model';

export * from './model';

export const AdditionalEdit: React.FC<AdditionalEditProps> = ({ data, onSubmit, resolvers = [], afterFields }) => {
  const languageCode = useCurrentLanguage();
  const { fieldNames, defaultValues } = useAdditionalEditFormData(data, languageCode);
  const isMobile = useIsMobile();

  const resolver = React.useMemo(() => composeResolvers(createFromValidationResolver(AdditionalEditFormModel), ...resolvers), [resolvers]);

  const form = useForm<AdditionalEditFormModel, FormContext>({
    defaultValues,
    resolver,
    context: {
      languageCode,
    },
  });

  return (
    <FormProvider {...form}>
      <Box
        component="form"
        noValidate
        onSubmit={form.handleSubmit((values) => onSubmit(values, { form }))}
        sx={{ width: 1, maxWidth: 550, display: 'inline-flex', flexDirection: 'column' }}
      >
        <Stack direction="column" gap={1.5}>
          {fieldNames.map((name) => (
            <Box key={name} sx={{ backgroundColor: 'gray ultra light', p: 1.5, borderRadius: '12px', overflow: 'hidden' }}>
              <FormField
                label={CATALOG_ITEM_ADDITIONAL_PARAMETERS_DICT[name]?.labelEditing ?? ''}
                labelPosition="left"
                name={name}
                type="switch"
              />
            </Box>
          ))}
        </Stack>

        {CAREER_IDS_WITH_ATTITUDE_TOWARDS_PETS_PARAMETER.includes(data.careerId) && (
          <FormField
            fieldContainerProps={{ sx: { width: 1, mt: 2.5 } }}
            fieldLabel="Опишите ваше отношение к животным"
            inputSx={{ minHeight: isMobile ? 174 : 103 }}
            name="translations.0.attitudeTowardsPets"
            placeholder="Введите текст"
            sx={{ width: 1 }}
            type="textArea"
          />
        )}

        {afterFields}

        <Button
          color="pink"
          sx={{
            width: { xs: 1, [MOBILE_BREAKPOINT_THRESHOLD]: 'auto' },
            mt: { xs: 2.5, [MOBILE_BREAKPOINT_THRESHOLD]: 5 },
            alignSelf: isMobile ? undefined : 'center',
          }}
          type="submit"
          variant="primary"
        >
          Cохранить
        </Button>
      </Box>
    </FormProvider>
  );
};
