import React from 'react';
import { Stack } from '@cp/ds/src/components/stack';
import { MessageWithActionsLayoutProps } from './types';

/**
 * @deprecated use InteractiveMessageLayout
 */
export const MessageWithActionsLayout: React.FC<MessageWithActionsLayoutProps> = ({ children }) => {
  return (
    // NOTE: Padding bottom is added to prevent boxShadow cutting of inner element
    <Stack direction="row" justifyContent="flex-start" sx={{ pb: 0.75 }}>
      <Stack
        alignItems="center"
        direction="column"
        sx={{
          width: 380,
          minWidth: '50%',
          maxWidth: '90%',
          p: 1.5,
          borderRadius: '12px',
          backgroundColor: 'gray light',
          boxShadow: '0px 2.17px 3.25px 0px rgba(0, 0, 0, 0.1)',
        }}
      >
        {children}
      </Stack>
    </Stack>
  );
};
