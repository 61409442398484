import { useMemo } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { adminCatalogItemApi } from 'src/entities/catalog/item';
import { SelectionMeta, VacancyMonitorService } from './vacancyMonitorService';
import { Selection } from './selections';
import { ReducerStateShape, selectColumnItems, slice } from './slice';

export const useVacancyMonitorService = (): VacancyMonitorService => {
  const dispatch = useDispatch<ThunkDispatch<ReducerStateShape, any, any>>();
  const store = useStore<ReducerStateShape>();
  return useMemo(() => {
    const metaStorage: Map<Selection, SelectionMeta> = new Map();
    let date: Date = new Date();
    return new VacancyMonitorService(
      { getItems: (q) => dispatch(adminCatalogItemApi.endpoints.getList.initiate(q)).unwrap() },
      {
        getSelectionItems: (threshold) => selectColumnItems(store.getState(), threshold.title),
        setSelectionItems: (items, threshold) => {
          dispatch(slice.actions.fill({ items, key: threshold.title }));
        },
        addSelectionItems: (items, threshold) => {
          dispatch(slice.actions.fill({ items, key: threshold.title }));
        },
        updateItem: (id, changes) => {
          dispatch(slice.actions.updateOne({ id, changes }));
        },
        clear: () => dispatch(slice.actions.clear()),
      },
      {
        clear: () => metaStorage.clear(),
        setSelectionMeta: (threshold: Selection, meta: SelectionMeta) => {
          metaStorage.set(threshold, meta);
        },
        getSelectionMeta: (threshold: Selection) => metaStorage.get(threshold),
        setBaseDate: (d) => {
          date = d;
        },
        getBaseDate: () => date,
      },
    );
  }, [dispatch, store]);
};
