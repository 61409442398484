import React, { useMemo } from 'react';
import { ClientType } from '@common/model/client/clientType';
import { Platform } from '@common/model/platform';
import { ClientTypeIcon } from 'src/entities/client';
import { useDeclination } from 'src/app/shared/hooks/useDeclination';
import { formatNumber } from '../lib';
import { coreClientStats } from '../api';
import { StatItem } from './statItem';

export const ClientActivity: React.FC<{ clientType: ClientType; title?: string }> = ({ clientType, title }) => {
  useDeclination();
  const now = useMemo(() => new Date().toISOString().slice(0, 10), []);
  const { data, isLoading } = coreClientStats.endpoints.getClientStats.useQuery({
    type: clientType,
    lastActivityAt: { after: `P7D/${now}` },
    registrationPlatform: { not: Platform.ApiServer },
    fake: false,
  });

  const { data: newbies } = coreClientStats.endpoints.getClientStats.useQuery({
    type: clientType,
    lastActivityAt: { after: `P7D/${now}` },
    createdAt: { after: `P7D/${now}` },
    registrationPlatform: { not: Platform.ApiServer },
    fake: false,
  });

  return (
    <StatItem
      isLoading={isLoading}
      label={
        <>
          <ClientTypeIcon type={clientType} /> Acti
        </>
      }
      title={title}
      value={`${formatNumber(data)} (${formatNumber(newbies)})`}
    />
  );
};
