import { ImageAsset, ImageAssetItem } from '@common/model/resource/image';
import { assumeMediaTypeByFileUrl } from './mediaType';

export const getResizedAssetItem = (
  sourceItem: ImageAssetItem,
  wantedWidth: number,
  detectMediaType?: (s: string) => string,
): ImageAssetItem | undefined => {
  const resizedItem = (sourceItem.images || []).find((resized) => resized.width === wantedWidth);
  if (!resizedItem) {
    return;
  }
  return {
    width: resizedItem.width,
    height: resizedItem.height,
    src: resizedItem.path,
    mimeType: sourceItem.mimeType || detectMediaType?.(resizedItem.path),
  };
};

export const getResizedAssetItems = (asset: ImageAsset, wantedSize: number): ImageAssetItem[] => {
  const result: ImageAssetItem[] = [];
  const base = getResizedAssetItem(asset.base, wantedSize, assumeMediaTypeByFileUrl);
  const webp = asset.webp ? getResizedAssetItem(asset.webp, wantedSize, assumeMediaTypeByFileUrl) : undefined;
  if (webp) {
    result.push(webp);
  }
  if (base) {
    result.push(base);
  }
  return result;
};
