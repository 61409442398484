import React, { memo } from 'react';
import { Typography, TypographyProps } from '@cp/ds/src/components/typography';
import { MessageContent } from '@cp/ds/src/components/messageContent';

export interface TextRendererProps {
  content: string;
  textOnly?: boolean;
}
export interface DialogMessageTextProps extends TypographyProps {
  /** Component to render text payload */
  TextRenderer?: (props: TextRendererProps) => JSX.Element | null;
  payload?: string;
  /* Do not render interactive elements */
  textOnly?: boolean;
}
export const DialogMessageText: React.FC<DialogMessageTextProps> = memo(
  ({ TextRenderer = MessageContent, children, payload, ...props }) => {
    return (
      <Typography variant="caption" {...props}>
        {payload && <TextRenderer content={payload} />}
        {children}
      </Typography>
    );
  },
);
DialogMessageText.displayName = 'DialogMessageText';
