import React from 'react';
import { OnlineStatus, OnlineStatusTag } from '@cp/ds/src/components/onlineStatus';
import { FormattingStrategy, HasOnlineStatus, approximateStrategy, getLastSeenText, isOnline } from '../model';

export interface ClientOnlineStatusTagProps {
  profile: HasOnlineStatus;
  strategy?: FormattingStrategy;
  now?: Date;
}

export const ClientOnlineStatusTag: React.FC<ClientOnlineStatusTagProps> = ({
  profile,
  strategy = approximateStrategy,
  now = new Date(),
}) => {
  const onlineStatus = isOnline(profile);
  const statusText = getLastSeenText({ ...profile, isOnline: onlineStatus }, strategy, now);

  return (
    <OnlineStatusTag colors={{ [OnlineStatus.Offline]: 'brand pink' }} online={onlineStatus}>
      {statusText}
    </OnlineStatusTag>
  );
};
