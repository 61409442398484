import React from 'react';
import {
  ResumeScheduleEdit as ResumeScheduleEditEntity,
  ResumeScheduleEditProps as ResumeScheduleEditEntityProps,
} from '@cp/entities/resume';
import { useDispatch } from 'react-redux';
import type { ThunkDispatch } from 'redux-thunk';
import { useApiServerErrorHandler } from 'src/app/shared/hooks/useApiServerErrorHandler';
import { useResumeData } from 'src/entities/resume';
import { adminResumeApi } from 'src/entities/resume/api';
import { ResumeScheduleEditProps } from './model';

export const ResumeScheduleEdit: React.FC<ResumeScheduleEditProps> = ({ resumeId, sx }) => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const handleApiServerError = useApiServerErrorHandler();
  const { resume, isLoadingResume } = useResumeData({ resumeId });

  const handleAddItem = React.useCallback<ResumeScheduleEditEntityProps['onAddItem']>(
    ({ dayOfWeek, from, to }) => {
      if (!resume) {
        throw new Error('Main resume is not loaded');
      }

      return dispatch(
        adminResumeApi.endpoints.createScheduleIntervalForResume.initiate({
          params: { id: resume.id },
          body: { dayOfWeek, from, to },
        }),
      );
    },
    [dispatch, resume],
  );

  const handleRemoveItem = React.useCallback<ResumeScheduleEditEntityProps['onRemoveItem']>(
    async ({ dayOfWeek, from, to }) => {
      if (!resume) {
        throw new Error('Main resume is not loaded');
      }

      const response = await dispatch(
        adminResumeApi.endpoints.deleteScheduleIntervalForResume.initiate({
          params: { id: resume.id },
          body: { dayOfWeek, from, to },
        }),
      );

      if ('error' in response) {
        handleApiServerError(response.error);
      }
    },
    [dispatch, handleApiServerError, resume],
  );

  return (
    <ResumeScheduleEditEntity
      data={resume?.schedule}
      isLoading={isLoadingResume}
      onAddItem={handleAddItem}
      onRemoveItem={handleRemoveItem}
      sx={sx}
    />
  );
};
