export enum NotificationTransport {
  Push = 'push',
  WebSocket = 'websocket',
  Sms = 'sms',
  Email = 'email',
}

export enum NotificationSendingTransportCondition {
  Always = 'always',
  IfNotSentYetByOtherTransports = 'ifNotSentYetByOtherTransports',
}

export interface INotificationSendingTransportConfig {
  transport: NotificationTransport;
  condition: NotificationSendingTransportCondition;
}

export type NotificationTransportsSequence = INotificationSendingTransportConfig[];
