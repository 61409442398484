import { styled } from '@cp/ds/src/theme';
import { name } from '../dialog/const';

export const MessageFooter = styled('footer', { name, slot: 'footer' })(({ theme }) => ({
  opacity: '0.6',
  gap: '2px',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'end',
  ...theme.typography.extraSmall,
}));
