/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC, ReactNode } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { SxProps } from '@mui/system'; // use mui because of incompatible type error in consumer component
import { ContentSkeleton } from '@cp/ds/src/components/contentSkeleton';
import { EntityId } from '@reduxjs/toolkit';
import { DialogMessageList } from '../dialogMessageList';
import { DialogMessageBlockPlain } from '../../model/dialogMessageDateBlock';
import { Root } from './root';
import { ListWrapper } from './listWrapper';
import { HeaderWrapper } from './headerWrapper';
import { PinnedWrapper } from './pinnedWrapper';

export { DialogContainer } from './container';
export { Tokens as DialogTokens, DESKTOP_LAYOUT_WIDTH } from './const';
export { PinnedMessage } from './pinnedMessage';

export interface DialogProps {
  id: EntityId | undefined | null;
  sx?: SxProps;
  hasMore: boolean;
  isLoadingMessages: boolean;
  loadedMessagesLength: number;
  loadMore: () => void;
  messageBlocks: DialogMessageBlockPlain[];
  scrollContainerRef: React.RefObject<HTMLDivElement>;
  headerContainerRef?: React.RefObject<HTMLDivElement>;
  header: ReactNode;
  footer: ReactNode;
  Loader: () => JSX.Element;
  children?: ReactNode | ReactNode[];
  pinned?: ReactNode | ReactNode[];
}
const style = { display: 'flex', flexDirection: 'column-reverse' } as const;

export const Dialog: FC<DialogProps> = ({
  id,
  sx,
  hasMore,
  loadMore,
  messageBlocks,
  header,
  footer,
  isLoadingMessages,
  loadedMessagesLength,
  scrollContainerRef,
  headerContainerRef,
  Loader,
  children,
  pinned,
}) => {
  return (
    <Root sx={sx}>
      <HeaderWrapper ref={headerContainerRef}>{header}</HeaderWrapper>
      <PinnedWrapper>{pinned}</PinnedWrapper>
      <ListWrapper ref={scrollContainerRef}>
        {isLoadingMessages && !messageBlocks?.length ? (
          <ContentSkeleton blocks={5} />
        ) : messageBlocks?.length && id ? (
          <InfiniteScroll
            dataLength={loadedMessagesLength}
            hasMore={hasMore}
            inverse
            loader={<Loader />}
            next={loadMore}
            scrollThreshold={0.95}
            scrollableTarget={scrollContainerRef.current as React.ReactNode}
            style={style}
          >
            <DialogMessageList blocks={messageBlocks} dialogId={id}>
              {children}
            </DialogMessageList>
          </InfiniteScroll>
        ) : (
          children
        )}
      </ListWrapper>
      {footer}
    </Root>
  );
};
