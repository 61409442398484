export enum NotificationSubscriptionType {
  Unknown = 'unknown',
  WebPush = 'webpush',

  /**
   * Firebase Cloud Messaging
   * @link https://firebase.google.com/docs/cloud-messaging
   */
  FCM = 'fcm',
}
