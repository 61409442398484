import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AdminCatalogItemViewModel } from '@common/model/catalog/item/admin.view';
import { AdminCatalogItemsListRequest } from '@common/model/catalog/item/adminList.request';
import { Box } from '@mui/material';
import { useForm } from 'react-hook-form';
import { AppFormProvider } from '@cp/utils/form';
import { FormField } from '@cp/ds/src/components/form';
import { CareerSelect } from '@cp/entities/career';
import { CitySearchFormField } from '@cp/entities/location';
import { CityViewModel } from '@common/model/location/cityViewModel';
import { Platform } from '@common/model/platform';
import { TaskBoard, TaskBoardConfig } from 'src/shared/ui/taskBoard';
import { adminCatalogItemApi } from 'src/entities/catalog/item';
import { useVacancyMonitorService } from '../model';
import { MatchingModal } from './matchingModal';
import { VacancyCard } from './vacancyCard';

export interface VacancyMonitorProps {
  selectedId?: number;
  onSelected?: (id?: number) => void;
}
export const VacancyMonitor: React.FC<VacancyMonitorProps> = ({ selectedId, onSelected }) => {
  const [localId, setLocalId] = useState(NaN);
  const id = selectedId || localId;
  const service = useVacancyMonitorService();
  const { data: currentItem } = adminCatalogItemApi.endpoints.getCatalogItem.useQuery({ params: { id } }, { skip: Number.isNaN(id) });
  const form = useForm<Partial<AdminCatalogItemsListRequest> & { city?: CityViewModel }>({});

  const onCardActivate = useCallback(
    (model: AdminCatalogItemViewModel) => {
      onSelected?.(model.id);
      setLocalId(model.id);
    },
    [onSelected],
  );
  const onClose = useCallback(() => {
    onSelected?.();
    setLocalId(NaN);
  }, [onSelected]);

  useEffect(() => {
    service.loadItems(new Date());
    return () => service.tearDown();
  }, [service]);

  form.watch(({ careerId, respondedAt, city, fake, registrationPlatform }) => {
    service.setFilters({ careerId, respondedAt, cityId: city?.id, fake, registrationPlatform });
  });

  const config: TaskBoardConfig<AdminCatalogItemViewModel> = useMemo(() => {
    return {
      columns: service.columns.map((threshold) => {
        return {
          key: threshold.title,
          title: threshold.title,
          itemKeyGenerator: (m) => m.id,
          renderItem: (m) => (
            <VacancyCard model={m} onActivate={onCardActivate} onUpdateModel={(update) => service.updateItem(m.id, update)} />
          ),
          itemsDataSelector: () => service.getCachedItemsBySelection(threshold),
          canLoadMoreSelector: () => service.hasMore(threshold),
          onLoadMore: () => service.loadMore(threshold),
        };
      }),
    };
  }, [service, onCardActivate]);

  return (
    <AppFormProvider form={form}>
      <Box sx={{ my: 2, mx: 2.5, display: 'flex', gap: 2, alignItems: 'center' }}>
        <CitySearchFormField placeholder="Поиска города..." />
        <CareerSelect />
        <FormField
          label="С откликами"
          labelPosition="right"
          name="respondedAt"
          onChange={(onlyResponded) => form.setValue('respondedAt', onlyResponded ? { isNotEmpty: true } : undefined)}
          sx={{ flexDirection: 'row' }}
          type="switch"
        />
        <FormField
          label="Без ботов"
          labelPosition="right"
          name="registrationPlatform"
          onChange={(enabled) => form.setValue('registrationPlatform', enabled ? { not: Platform.ApiServer } : undefined)}
          sx={{ flexDirection: 'row' }}
          type="switch"
        />
        <FormField
          label="Без фейков"
          labelPosition="right"
          name="fake"
          sx={{ flexDirection: 'row' }}
          transform={{
            in: (v) => {
              if (v === false) {
                return undefined;
              }
              return typeof v !== 'undefined' ? false : undefined;
            },
            out: (v) => {
              return typeof v !== 'undefined';
            },
          }}
          type="switch"
        />
      </Box>
      <TaskBoard config={config} sx={{ mx: 2.5 }} />
      {Number.isNaN(id) || <MatchingModal catalogItemId={id} isOpen={!Number.isNaN(id)} onClose={onClose} vacancy={currentItem} />}
    </AppFormProvider>
  );
};
