import { styled } from '@cp/ds/src/theme';
import { Box } from '@cp/ds/src/components/box';
// @ts-expect-error esm import
import { ContainerCssTokens } from '@cp/ui/components';
import { DESKTOP_LAYOUT_WIDTH, Tokens, name } from './const';

export const Root = styled(Box, { name })(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  containerType: 'inline-size',
  width: '100%',
  boxSizing: 'border-box',
  backgroundColor: Tokens.listBackground(theme.palette['gray background']),
  [Tokens.gap]: '12px',
  [Tokens.colorPaper]: theme.palette.background.default,
  [ContainerCssTokens.offset]: Tokens.gap(),
  [ContainerCssTokens.width]: Tokens.maxWidth('660px'),
  [Tokens.listGap]: theme.spacing(1.5),
  [`@container (min-width: ${DESKTOP_LAYOUT_WIDTH})`]: {
    [Tokens.listGap]: theme.spacing(2.5),
    [Tokens.headerGap]: theme.spacing(1),
  },
}));
Root.displayName = `Entities/${name}`;
