import React from 'react';
import {
  DialogMessageRenderer,
  DialogMessageText,
  MessageDate,
  MessageFooter,
  TextMessageLayout,
  useIsOutgoing,
} from '@cp/entities/dialog';
import { MessageType } from '@common/model/dialog/message/messageType';

export const PromocodeMessageRenderer: DialogMessageRenderer<MessageType.Promocode> = (props) => {
  const { message, isPreview } = props;
  const { id } = message.payload;
  const outgoing = useIsOutgoing(message);

  if (isPreview) {
    return <DialogMessageText>Промокод {id}</DialogMessageText>;
  }

  return (
    <TextMessageLayout outgoing={outgoing}>
      <DialogMessageText>
        {outgoing ? 'Вы отправили' : 'Вы получили'} промокод {id}
      </DialogMessageText>
      <MessageFooter>
        <MessageDate message={message} />
      </MessageFooter>
    </TextMessageLayout>
  );
};
