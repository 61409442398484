// @ts-expect-error esm import
import { Container } from '@cp/ui/components';
import { styled } from '@cp/ds/src/theme';
import { name } from './const';

export const DialogContainer = styled(Container, { name, slot: 'Container' })({
  width: '100%',
  boxSizing: 'border-box',
  '&:empty': { display: 'none' },
});
