import React from 'react';
import { DialogMessageRenderer, DialogTextMessage } from '@cp/entities/dialog';
import { MessageType } from '@common/model/dialog/message/messageType';
import { dialogMembersSelector } from '@cp/entities/dialog/model/store/selectors';
import { EntityId } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { DialogReducerStateShape } from '@cp/entities/dialog/model/store/dialog';

export const FavoriteNotificationResponseRenderer: DialogMessageRenderer<MessageType.NotificationFavoriteResponse> = (props) => {
  const members = useSelector((state) => dialogMembersSelector(state as DialogReducerStateShape, props.dialogId as EntityId));
  const targetMember = members.find((m) => m.entityId === props.message.payload.responseToClientId);

  return (
    <DialogTextMessage
      {...props}
      message={{
        ...props.message,
        payload: `Здравствуйте${targetMember?.name ? ` ${targetMember.name}` : ''}, ${props.message.payload.isPositive ? 'да' : 'нет не'} работаю`,
      }}
    />
  );
};
