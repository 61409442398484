export * from './useUnreadMessagesCount';
export * from './useCurrentDialogMember';
export * from './useMessageResolver';
export * from './useIsOutgoing';
export * from './getMessageDescription';
export { dialogReducerConfig, dialogSlice } from './store/dialog';
export { createDialogMiddleware } from './store/dialogMiddleware';
import { dialogSlice } from './store/dialog';
import * as dialogSelectors from './store/selectors';
import * as currentDialogSelectors from './store/currentDialogSelectors';
export type { DialogReducerStateShape as DialogStateShape } from './store/dialog';
export * from './ports';
export { DialogUserContextProvider, useCurrentUserContext } from './user.ctx';
export * from './useIsOnline';

export const dialogModel = {
  actions: dialogSlice.actions,
  selectors: dialogSelectors,
};

export const currentDialogModel = {
  selectors: currentDialogSelectors,
};
