import { useMemo } from 'react';
import { CAREER_IDS_ALLOWED_FOR_DEALS } from '@common/const/deal';
import { CatalogItemViewModel } from '@common/model/catalog/item/view';
import { formatVacancyDates } from './formatVacancyDates';

export const useTitle = (data: CatalogItemViewModel | undefined): string => {
  return useMemo(() => {
    if (!data) {
      return '';
    }

    if (data.translation?.title) {
      return data.translation.title;
    } else if (CAREER_IDS_ALLOWED_FOR_DEALS.includes(data.careerId)) {
      return formatVacancyDates(data);
    } else {
      return '';
    }
  }, [data]);
};
