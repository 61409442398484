import { ApiProperty } from '@nestjs/swagger';
import { Type } from 'class-transformer';
import {
  ArrayMaxSize,
  ArrayNotEmpty,
  IsBoolean,
  IsEnum,
  IsNumber,
  IsOptional,
  IsString,
  Max,
  Min,
  ValidateIf,
  ValidateNested,
} from 'class-validator';
import type { ApiServerEntityIDType } from '../../../types';
import { LanguageCode } from '../../resource/language';
import {
  CAREER_IDS_WITH_APARTMENT_PARAMETER,
  CAREER_IDS_WITH_BABY_PARAMETER,
  CAREER_IDS_WITH_BEDRIDDEN_PATIENT_PARAMETER,
  CAREER_IDS_WITH_CHILDREN_PARAMETER,
  CAREER_IDS_WITH_CHILD_WITH_DISABILITIES_PARAMETER,
  CAREER_IDS_WITH_COUNTRY_HOUSE_PARAMETER,
  CAREER_IDS_WITH_EXPERIENCE_PARAMETER,
  CAREER_IDS_WITH_FREE_MEETING_WITH_PARENTS_PARAMETER,
  CAREER_IDS_WITH_LIVING_AT_CONSUMERS_HOME,
  CAREER_IDS_WITH_PATRONAGE_PARAMETER,
  CAREER_IDS_WITH_PRESCHOOLER_PARAMETER,
  CAREER_IDS_WITH_REGULAR_SPECIALIST_PARAMETER,
  CAREER_IDS_WITH_SCHOOLCHILD_PARAMETER,
  CAREER_IDS_WITH_WORKING_AT_CONSUMERS_HOME_PARAMETER,
  CAREER_IDS_WITH_WORKING_AT_HOSPITAL_PARAMETER,
  CATALOG_ITEM_PRICE_MIN,
  CATALOG_ITEM_TARIFFS_COUNT_MAX,
  EXPERIENCE_MAX,
  EXPERIENCE_MIN,
} from '../../../const/catalog/item';
import { validatorMessageFromDictionary } from '../../../utils/validation/i18n/createMessagesDictionary';

export class ResumeUpdateRequestTranslation {
  @ApiProperty({ enum: LanguageCode, enumName: 'LanguageCode' })
  @IsEnum(LanguageCode)
  languageCode: LanguageCode;

  @ApiProperty()
  @IsOptional()
  @IsString()
  description?: string;

  @ApiProperty()
  @IsOptional()
  @IsString()
  attitudeTowardsPets?: string;
}

export class ResumeUpdateRequestTariff {
  @ApiProperty()
  @IsNumber()
  @Min(1)
  childrenCount: number;

  @ApiProperty()
  @IsNumber()
  @Min(CATALOG_ITEM_PRICE_MIN, {
    message: validatorMessageFromDictionary({
      [LanguageCode.Russian]: `Минимальная цена ${CATALOG_ITEM_PRICE_MIN} ₽`,
    }),
  })
  price: number;
}

export class ResumeUpdateRequest {
  @ApiProperty()
  @IsNumber()
  @Min(1)
  careerId: ApiServerEntityIDType;

  @ApiProperty()
  @IsOptional()
  @ValidateIf((o) => CAREER_IDS_WITH_EXPERIENCE_PARAMETER.includes(o.careerId))
  @IsNumber()
  @Min(EXPERIENCE_MIN)
  @Max(EXPERIENCE_MAX)
  experience?: number;

  @ApiProperty({ required: false, isArray: true })
  @IsOptional()
  @IsNumber({}, { each: true })
  @Min(1, { each: true })
  optionIds?: ApiServerEntityIDType[];

  @ApiProperty({ type: ResumeUpdateRequestTranslation, isArray: true })
  @IsOptional()
  @Type(() => ResumeUpdateRequestTranslation)
  @ValidateNested()
  @ArrayNotEmpty()
  translations?: ResumeUpdateRequestTranslation[];

  @ApiProperty({ required: false })
  @IsOptional()
  @Type(() => ResumeUpdateRequestTariff)
  @ValidateIf((o) => CAREER_IDS_WITH_CHILDREN_PARAMETER.includes(o.careerId))
  @ValidateNested()
  @ArrayNotEmpty()
  @ArrayMaxSize(CATALOG_ITEM_TARIFFS_COUNT_MAX)
  tariffs?: ResumeUpdateRequestTariff[];

  @ApiProperty()
  @IsOptional()
  @ValidateIf((o) => !CAREER_IDS_WITH_CHILDREN_PARAMETER.includes(o.careerId))
  @IsNumber()
  @Min(1)
  price?: number;

  @ApiProperty({ required: false })
  @IsOptional()
  @ValidateIf((o) => CAREER_IDS_WITH_BABY_PARAMETER.includes(o.careerId))
  @IsBoolean()
  baby?: boolean;

  @ApiProperty({ required: false })
  @IsOptional()
  @ValidateIf((o) => CAREER_IDS_WITH_CHILD_WITH_DISABILITIES_PARAMETER.includes(o.careerId))
  @IsBoolean()
  childWithDisabilities?: boolean;

  @ApiProperty({ required: false })
  @IsOptional()
  @ValidateIf((o) => CAREER_IDS_WITH_REGULAR_SPECIALIST_PARAMETER.includes(o.careerId))
  @IsBoolean()
  regularSpecialist?: boolean;

  @ApiProperty({ required: false })
  @IsOptional()
  @ValidateIf((o) => CAREER_IDS_WITH_FREE_MEETING_WITH_PARENTS_PARAMETER.includes(o.careerId))
  @IsBoolean()
  freeMettingWithParents?: boolean;

  @ApiProperty({ required: false })
  @IsOptional()
  @ValidateIf((o) => CAREER_IDS_WITH_BEDRIDDEN_PATIENT_PARAMETER.includes(o.careerId))
  @IsBoolean()
  bedriddenPatient?: boolean;

  @ApiProperty({ required: false })
  @IsOptional()
  @ValidateIf((o) => CAREER_IDS_WITH_WORKING_AT_HOSPITAL_PARAMETER.includes(o.careerId))
  @IsBoolean()
  workingAtHospital?: boolean;

  @ApiProperty({ required: false })
  @IsOptional()
  @ValidateIf((o) => CAREER_IDS_WITH_PATRONAGE_PARAMETER.includes(o.careerId))
  @IsBoolean()
  patronage?: boolean;

  @ApiProperty({ required: false })
  @IsOptional()
  @ValidateIf((o) => CAREER_IDS_WITH_WORKING_AT_CONSUMERS_HOME_PARAMETER.includes(o.careerId))
  @IsBoolean()
  workingAtConsumersHome?: boolean;

  @ApiProperty({ required: false })
  @IsOptional()
  @ValidateIf((o) => CAREER_IDS_WITH_PRESCHOOLER_PARAMETER.includes(o.careerId))
  @IsBoolean()
  preschooler?: boolean;

  @ApiProperty({ required: false })
  @IsOptional()
  @ValidateIf((o) => CAREER_IDS_WITH_SCHOOLCHILD_PARAMETER.includes(o.careerId))
  @IsBoolean()
  schoolchild?: boolean;

  @ApiProperty({ required: false })
  @IsOptional()
  @ValidateIf((o) => CAREER_IDS_WITH_APARTMENT_PARAMETER.includes(o.careerId))
  @IsBoolean()
  apartment?: boolean;

  @ApiProperty({ required: false })
  @IsOptional()
  @ValidateIf((o) => CAREER_IDS_WITH_COUNTRY_HOUSE_PARAMETER.includes(o.careerId))
  @IsBoolean()
  countryHouse?: boolean;

  @ApiProperty({ required: false })
  @IsOptional()
  @ValidateIf((o) => CAREER_IDS_WITH_LIVING_AT_CONSUMERS_HOME.includes(o.careerId))
  @IsBoolean()
  livingAtConsumersHome?: boolean;
}
