import React from 'react';
import { ItemCreationData, generateItemCreationData } from './generateItemCreationData';

export type UseItemCreationDataReturn = ItemCreationData;

export const useItemCreationData = (parameters: Parameters<typeof generateItemCreationData>[0]) => {
  const { intervals, startFieldValue, endFieldValue } = parameters;
  return React.useMemo(
    () =>
      generateItemCreationData({
        intervals,
        startFieldValue,
        endFieldValue,
      }),
    [startFieldValue, intervals, endFieldValue],
  );
};
