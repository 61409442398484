import React from 'react';
import { IResumeViewContext, ResumeViewContext } from 'src/features/resume/view';
import { IResumeViewHandle, ResumeView } from 'src/widgets/resume';
import { DialogViewer } from 'src/widgets/dialog';
import { DialogViewContextProvider } from 'src/features/dialog/view';

const dialogViewerValue = { Viewer: DialogViewer };

export const ResumeViewerProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const viewerRef = React.useRef<IResumeViewHandle>(null);

  const value = React.useMemo<IResumeViewContext>(
    () => ({
      view: (id, onClose) => {
        viewerRef.current?.view(id, onClose);
      },
    }),
    [],
  );

  return (
    <ResumeViewContext.Provider value={value}>
      <DialogViewContextProvider value={dialogViewerValue}>
        <ResumeView ref={viewerRef} />
      </DialogViewContextProvider>
      {children}
    </ResumeViewContext.Provider>
  );
};
