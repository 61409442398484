import { format } from 'date-fns';
import { DateFormat } from '@common/const/date';
import { ru } from '@cp/shared/lib/date';
import { DateISOString } from '@common/types';

export interface HasStartEndDate {
  startedAt?: DateISOString;
  endedAt?: DateISOString;
}

export const formatVacancyDates = (model: HasStartEndDate) => {
  const start = model.startedAt ? new Date(model.startedAt) : undefined;
  const end = model.endedAt ? new Date(model.endedAt) : undefined;
  let t = '';
  if (start) {
    t += `${format(start, DateFormat.CatalogItemDateThisYear, { locale: ru })} ${format(start, DateFormat.Time)}`;
  }
  if (end) {
    t += ` - ${format(end, DateFormat.Time)}`;
  }
  return t;
};
