import { AdminCatalogItemsListRequest, AdminCatalogItemsListResponse } from '@common/model/catalog/item/adminList.request';
import { CatalogItemType } from '@common/model/catalog/item/type';
import { Selection, selections } from './selections';
import { getSelectionInterval } from './getSelectionInterval';

export interface IVacancyMatchApi {
  getItems(q: AdminCatalogItemsListRequest): Promise<AdminCatalogItemsListResponse>;
}

export interface IVacancyMatchStorage {
  setSelectionItems: (items: AdminCatalogItemsListResponse['items'], threshold: Selection) => void;
  addSelectionItems: (items: AdminCatalogItemsListResponse['items'], threshold: Selection) => void;
  getSelectionItems: (threshold: Selection) => AdminCatalogItemsListResponse['items'];
  updateItem: (
    id: AdminCatalogItemsListResponse['items'][number]['id'],
    update: Partial<AdminCatalogItemsListResponse['items'][number]>,
  ) => void;
  clear: () => void;
}

export type SelectionMeta = Partial<AdminCatalogItemsListRequest> & { totalCount: number; page: number; pagination: number };

export interface IVacancyMatchMetaStorage {
  setBaseDate: (date: Date) => void;
  getBaseDate(): Date;
  setSelectionMeta(threshold: Selection, meta: SelectionMeta): void;
  getSelectionMeta(threshold: Selection): SelectionMeta | undefined;
  clear(): void;
}

export class VacancyMonitorService {
  readonly #selections: Selection[] = [];

  constructor(
    private readonly api: IVacancyMatchApi,
    private readonly storage: IVacancyMatchStorage,
    private readonly metaStorage: IVacancyMatchMetaStorage,
  ) {
    this.#selections = selections;
  }

  get columns() {
    return this.#selections;
  }

  tearDown() {
    this.storage.clear();
    this.metaStorage.clear();
  }

  getCachedItemsBySelection(threshold: Selection) {
    return this.storage.getSelectionItems(threshold);
  }

  hasMore(threshold: Selection): boolean {
    const items = this.getCachedItemsBySelection(threshold);
    const meta = this.metaStorage.getSelectionMeta(threshold);
    return Boolean(meta && items.length < meta.totalCount);
  }

  loadItems(baseDate: Date) {
    this.metaStorage.setBaseDate(baseDate);
    return Promise.all(this.columns.map((t) => this.loadSelectionItems(t)));
  }

  updateItem: IVacancyMatchStorage['updateItem'] = (...args) => {
    return this.storage.updateItem(...args);
  };

  setFilters(filters?: Partial<AdminCatalogItemsListRequest>) {
    this.storage.clear();
    return Promise.all(
      this.columns.map((t) => {
        const meta = this.getMetaOrDefaults(t);
        this.metaStorage.setSelectionMeta(t, { ...meta, ...filters });
        return this.loadSelectionItems(t);
      }),
    );
  }

  loadMore(threshold: Selection) {
    const meta = this.getMetaOrDefaults(threshold);
    this.metaStorage.setSelectionMeta(threshold, { ...meta, page: VacancyMonitorService.calcNextPage(meta) });
    return this.loadSelectionItems(threshold);
  }

  private getMetaOrDefaults(threshold: Selection): SelectionMeta {
    return {
      page: 1,
      pagination: VacancyMonitorService.#DEFAULT_PAGINATION,
      totalCount: 0,
      ...this.metaStorage.getSelectionMeta(threshold),
    };
  }

  async loadSelectionItems(threshold: Selection) {
    const baseDate = this.metaStorage.getBaseDate();
    const { totalCount: _, ...filters } = this.getMetaOrDefaults(threshold);
    const { items, totalCount } = await this.api.getItems({
      type: CatalogItemType.Vacancy,
      isPublished: true,
      matchedAt: { isEmpty: true },
      sorters: { createdAt: 'desc' },
      createdAt: getSelectionInterval(threshold, baseDate),
      ...threshold.filter,
      ...filters,
    });
    this.storage.addSelectionItems(items, threshold);
    this.metaStorage.setSelectionMeta(threshold, { ...filters, totalCount });
  }

  static calcNextPage({ page, pagination, totalCount }: { page: number; pagination: number; totalCount: number }) {
    const maxPage = Math.ceil(totalCount / pagination);
    return Math.min(page + 1, maxPage);
  }

  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  static #DEFAULT_PAGINATION = 20;
}
