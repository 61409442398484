import { DateFormat } from '@common/const/date';
import { addMilliseconds, differenceInMilliseconds, format, parse, startOfDay } from 'date-fns';

export const timeStringToMs = (time: string): number => {
  const refDate = startOfDay(new Date());
  const date = parse(time, DateFormat.Time, refDate);

  if (Number.isNaN(date.getTime())) {
    throw new Error(`Time parsing failed for value "${time}"`);
  }

  return differenceInMilliseconds(date, refDate);
};

export const msToTimeString = (time: number): string => {
  const refDate = startOfDay(new Date());
  const date = addMilliseconds(refDate, time);

  return format(date, DateFormat.Time);
};
