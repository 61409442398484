import { UseFormReturn } from 'react-hook-form';
import { coreDialogApi } from '@cp/entities/dialog';
import { DialogMemberType } from '@common/model/dialog/dialogMemberType';
import { MessageType } from '@common/model/dialog/message/messageType';
import { useCallback } from 'react';
import { useApiServerErrorHandler } from 'src/app/shared/hooks/useApiServerErrorHandler';

export const useSendMessageToClient = () => {
  const [ensureDialog] = coreDialogApi.useCreateDialogMutation();
  const [sendMessage] = coreDialogApi.useSendMessageMutation();
  const handleApiServerError = useApiServerErrorHandler();

  return useCallback(
    async (
      { clientId, message, sendCopyBySms }: { clientId: number; message: string; sendCopyBySms?: boolean },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      { form }: { form?: UseFormReturn<any> } = {},
    ) => {
      const dialogResponse = await ensureDialog({ participantId: clientId, participantType: DialogMemberType.Client });

      if ('error' in dialogResponse) {
        handleApiServerError(dialogResponse.error, { form });
        return;
      }

      const response = await sendMessage({
        sendCopyBySms: sendCopyBySms ? 1 : undefined,
        dialogId: dialogResponse.data.id,
        payload: message,
        type: MessageType.Text,
      });

      if ('error' in response && response.error) {
        handleApiServerError(response.error, { form });
        return;
      }
    },
    [ensureDialog, handleApiServerError, sendMessage],
  );
};
