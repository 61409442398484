import { MessageType } from '@common/model/dialog/message/messageType';
import { DialogMessageRenderer, DialogTextMessage } from '@cp/entities/dialog';
import { FavoriteNotificationRenderer } from './favorite';
import { VacancyResponseRenderer } from './vacancyResponse';
import { ClientContactsRenderer } from './clientContacts';
import { FavoriteNotificationResponseRenderer } from './favoriteResponse';
import { UnFavoriteNotificationRenderer } from './unfavorite';
import { PromocodeMessageRenderer } from './promocode';
import { TrainingMessageRenderer } from './training';

export type MessageTypeToComponentMap = {
  [T in MessageType]: DialogMessageRenderer<T>;
};

export const messageComponentMap: Partial<MessageTypeToComponentMap> = {
  [MessageType.Text]: DialogTextMessage,
  [MessageType.NotificationFavorite]: FavoriteNotificationRenderer,
  [MessageType.NotificationUnFavorite]: UnFavoriteNotificationRenderer,
  [MessageType.NotificationFavoriteResponse]: FavoriteNotificationResponseRenderer,
  [MessageType.VacancyResponse]: VacancyResponseRenderer,
  [MessageType.ClientContacts]: ClientContactsRenderer,
  [MessageType.Promocode]: PromocodeMessageRenderer,
  [MessageType.Training]: TrainingMessageRenderer,
} as const;
