import React from 'react';
import { MessageType } from '@common/model/dialog/message/messageType';
import {
  DialogMessageRenderer,
  DialogMessageText,
  MessageDate,
  MessageDelivery,
  MessageFooter,
  TextMessageLayout,
  useIsOutgoing,
} from '@cp/entities/dialog';

export const TrainingMessageRenderer: DialogMessageRenderer<MessageType.Training> = ({ isPreview, message }) => {
  const outgoing = useIsOutgoing(message);
  return isPreview ? (
    <DialogMessageText>Хотите пройти обучение сейчас?</DialogMessageText>
  ) : (
    <TextMessageLayout outgoing={outgoing}>
      <DialogMessageText>Хотите пройти обучение сейчас?</DialogMessageText>
      <MessageFooter>
        <MessageDate message={message} />
        <MessageDelivery message={message} />
      </MessageFooter>
    </TextMessageLayout>
  );
};
