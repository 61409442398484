import { ClientPhotoPurpose } from '@common/model/client/photo/purpose';
import { ClientPhotoViewModel } from '@common/model/client/photo/view';
import { ImageAsset } from '@common/model/resource/image';

export const getPhotoAsset = (photos: ClientPhotoViewModel[], purpose?: ClientPhotoPurpose): ImageAsset | undefined => {
  if (purpose) {
    return photos.find((photo) => photo.purpose === purpose)?.image;
  }
  return photos.find((photo) => photo.isMain)?.image || photos[0]?.image;
};
