import React from 'react';
import { Stack } from '@cp/ds/src/components/stack';
import { Typography } from '@cp/ds/src/components/typography';
import { Button } from '@cp/ds/src/components/button';
import { CloseIcon } from '@cp/ds/src/icons';
import { MOBILE_BREAKPOINT_THRESHOLD } from '@cp/ds/src/theme';
import { ItemProps } from './model';

export const Item: React.FC<ItemProps> = ({ interval, onRemove }) => {
  const { from, to } = interval;
  const [isRemoving, setRemoving] = React.useState(false);

  const handleRemoveButtonClick = async () => {
    setRemoving(true);
    try {
      await onRemove(interval);
    } finally {
      setRemoving(false);
    }
  };

  return (
    <Stack
      direction="row"
      sx={{
        alignItems: 'center',
        px: 1,
        py: 0.5,
        backgroundColor: { xs: 'transparent', [MOBILE_BREAKPOINT_THRESHOLD]: 'white' },
        borderRadius: '20px',
      }}
    >
      <Typography sx={{ color: isRemoving ? 'gray middle' : undefined }} variant="caption">
        {from} - {to}
      </Typography>
      <Button disabled={isRemoving} onClick={handleRemoveButtonClick} sx={{ ml: 1 }} variant="text">
        <CloseIcon sx={{ fontSize: { xs: '1.5rem', [MOBILE_BREAKPOINT_THRESHOLD]: '1rem' } }} />
      </Button>
    </Stack>
  );
};
